export const rtvProPartnerRoleRoute = roles => {
  if (!roles) {
    return 'landing';
  }

  const partnerRoles = [
    'ROLE_RESELLER',
    'ROLE_RESELLER_PENDING',
    'ROLE_RESELLER_PENDING_EVALUATION',
    'ROLE_RESELLER_PENDING_MATCH',
    'ROLE_SUPPLIER',
    'ROLE_SUPPLIER_PENDING'
  ];

  // get the first partner role element by iterate the whole roles array
  // IMPORTANT: we are taking for granted that we shouldn't have more than one partner role per user
  const partnerRole = roles.find(role => partnerRoles.includes(role));

  if (!partnerRole) {
    return 'landing';
  }

  switch (partnerRole) {
    case 'ROLE_RESELLER_PENDING':
    case 'ROLE_RESELLER_PENDING_EVALUATION':
      return '/rtv-pro/capacitacion/';
    case 'ROLE_RESELLER':
    case 'ROLE_RESELLER_PENDING_MATCH':
    case 'ROLE_SUPPLIER':
    case 'ROLE_SUPPLIER_PENDING':
      return '/rtv-pro/mi-panel/';
    default:
      return 'landing';
  }
};
