import { useRef, useEffect } from 'react';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useGetGoogleCliendId = () => {
  useEffect(() => {
    const ga_client_id = localStorage.getItem('ga_client_id');

    if (!ga_client_id) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}`;
      document.body.appendChild(script);
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };

      const gclidPromise = new Promise(resolve => {
        window.gtag(
          'get',
          process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
          'client_id',
          resolve
        );
      });

      gclidPromise.then(clientId => {
        localStorage.setItem('ga_client_id', clientId);
      });
    }
  }, []);
};
